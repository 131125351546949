import React, { createContext, useEffect, useReducer } from "react";

export const ThemeContext = createContext("dark");

const initialState = {theme: "dark"}

function reducer(state, action) {
    switch (action.type) {
      case "SET_DARK": {
        return {
          ...state,
          theme: "dark",
        }
      }
      case "SET_LIGHT": {
        return {
          ...state,
          theme: "light",
        }
      }
      default:
        throw new Error("Bad Action Type")
    }
  }

export const ThemeProvider = ({ children }) => {

  const [theme, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (typeof window !== "undefined") {
        let mode = localStorage.getItem("mode");
      if (mode) {        
        if(mode==="dark") {
            dispatch({ type: "SET_DARK" })
        } else {
            dispatch({ type: "SET_LIGHT" })
        }        
      } else {
        dispatch({ type: "SET_DARK" })
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (theme.theme === "light") {
        document.documentElement.setAttribute("data-theme", "light");
        localStorage.setItem("mode", "light");
      } else {
        document.documentElement.setAttribute("data-theme", "");
        localStorage.setItem("mode", "dark");
      }
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={[theme, dispatch]}>
      {children}
    </ThemeContext.Provider>
  );
};
